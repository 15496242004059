<div class="flex-container">
    <button
        mat-flat-button
        *ngIf="hasResultDocument() || hasSummaryDocument()"
        color="accent"
        class="right-icon-button"
        [class.spinner]="isSaving"
        [disabled]="isSaving"
        aria-label="Download Result Document"
        data-testid="result-document-button"
        [matMenuTriggerFor]="downloadMenu">
        <mat-icon
            aria-hidden="false"
            aria-label="Download icon">
            keyboard_arrow_down
        </mat-icon>
        <mat-label>Documents</mat-label>
    </button>

    <mat-menu #downloadMenu="matMenu">
        <button mat-menu-item 
            (click)="openResultDocument()" 
            *ngIf="hasResultDocument()">
            <mat-icon color="primary">download</mat-icon>
            <span class="primary">Report Document</span>
        </button>

        <button mat-menu-item 
            (click)="openSummaryDocument()" 
            *ngIf="hasSummaryDocument()">
            <mat-icon color="primary">download</mat-icon>
            <span class="primary">Summary Document</span>
        </button>
    </mat-menu>
</div>