import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, from, lastValueFrom, map } from 'rxjs';
import { BaseOrder, Order } from '../../interfaces/orders/order';
import { OrderResult } from '../../interfaces/order-result';
import { PatchDocument } from '../../interfaces/patch-document';
import { RegHubApiResult } from '../../interfaces/reghubapi-result';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { eQCFormType } from '../../enums';

export class OrderRequestOptions {
  sortColumn?: string;
  sortDirection?: string;
  states?: string[];
  isChildSearch?: boolean;

  constructor() { }
}

@Injectable({
  providedIn: 'root'
})
export class OrderRepositoryService extends PaginatedRepository<Order> {
  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this._baseLink = `${config.rootUrl}/Orders`;
  }

  // CRUD Operations ---------------
  public getOrder = (route: string, orderID: string) => {
    // Get Orders
    return this.http.get<Order>(
      this.createCompleteRoute(route + "/" + orderID, this.config.rootUrl));
  }

  public getTopTenFailedOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'failedAutomation', 'registryOutage', 'exception','errorcorrection' ], sortColumn: 'OrderStatusUpdatedDate' })
      .pipe(map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        }
    }));
  }

  public getTopTenManualOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'manualFulfillment' ], sortColumn: 'OrderStatusUpdatedDate' }).pipe(map(response => {
      return {
        total: this.parseResourceCountHeader(response.headers),
        resource: response.body ?? []
      }
    }));
  }

  public getTopTenDraftOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'draft', 'historicalDraft' ], isChildSearch: false }).pipe(map(response => {
      return {
        total: this.parseResourceCountHeader(response.headers),
        resource: response.body ?? []
      }
    }));
  }

  public getTopTenPendingOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'pending' ], isChildSearch: false }).pipe(map(response => {
      return {
        total: this.parseResourceCountHeader(response.headers),
        resource: response.body ?? []
      }
    }));
  }

  public getTopTenPendingAndExceptionOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'pending', 'awaitinghighvolumeresults', 'exception', 'registryoutage', 'outofregistryhours' ], isChildSearch: false})
      .pipe(map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        }
      }));
  }

  public getTopTenInvalidOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'invalid' ], isChildSearch: false })
      .pipe(map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        }
      }));
  }

  public getTopTenCompleteOrders(): Observable<{ total: number, resource: Order[] }> {
    return this.getTopTenOrders({ states: [ 'complete', 'cancelled' ], isChildSearch: false })
      .pipe(map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        }
      }));
  }

  public getTopTenOrders(options?: OrderRequestOptions) {
    let params = new HttpParams()
      .set('isVisible', true)
      .set('sortColumn', options?.sortColumn ?? 'added')
      .set('sortDirection', options?.sortDirection ?? 'desc')
      .set('pageSize', 10)
      .set('pageNumber', 1);

    if(options?.isChildSearch !== undefined) {
      params = params.set('isChildSearch', options.isChildSearch);
    }

    if(options?.states && options.states?.length > 0) {
      for (var i = 0; i < options.states.length; i++) {
        params = params.append('status', options.states[i]);
      }
    }

    return this.http.get<Order[]>(this._baseLink, { observe: 'response', params: params });
  }

  public getActiveLiens(jurisdiction: string) {
    var params = new HttpParams()
      .set('status', 'Complete')
      .set('jurisdiction', jurisdiction)
      .set('isVisible', true)
      .set('sortColumn', 'OrderStatusUpdatedDate')
      .set('sortDirection', 'desc');

    params = params.append('orderType', 'Lien');
    params = params.append('orderType', 'RepairLien');

    return this.http.get<Order[]>(this._baseLink, { observe: 'response', params: params });
  }

  public createNewOrder = (order: BaseOrder, historical: boolean = false) => {
    var route = historical ? "orders/historical" : "orders";
    return this.createOrder(route, order)
  }

  public createOrder = (route: string, order: BaseOrder) => {
    return this.http.post<Order>(
      this.createCompleteRoute(route, this.config.rootUrl),
      order,
      this.generateHeaders());
  }

  public duplicateOrder(orderId: string, jurisdiction: string, formType: eQCFormType | null = null) {
    return this.http.post(
      this.createCompleteRoute(`Orders/${orderId}/Duplicate`, this.config.rootUrl),
      { jurisdiction, formType },
      this.generateHeaders());
  }

  public updateOrder = (route: string, order: BaseOrder) => {
    return this.http.put(this.createCompleteRoute(route, this.config.rootUrl), order, this.generateHeaders());
  }

  public patchOrder = (route: string, patchDocument: PatchDocument[]) => {
    return this.http.patch(this.createCompleteRoute(route, this.config.rootUrl), patchDocument, this.generateHeaders());
  }

  public deleteOrder = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this.config.rootUrl));
  }

  public enterResults = (route: string, results: OrderResult) => {
    return this.http.put<RegHubApiResult>(this.createCompleteRoute(route, this.config.rootUrl), results, this.generateHeaders());
  }

  public lockOrder = (orderID: string) => {
    return this.http.put(this.createCompleteRoute("orders/" + orderID + "/lock", this.config.rootUrl), {}, this.generateHeaders());
  }

  public unlockOrder = (orderID: string) => {
    return this.http.put(this.createCompleteRoute("orders/" + orderID + "/unlock", this.config.rootUrl), {}, this.generateHeaders());
  }

  public getOrdersWithUnreadAdminMessages(pageSize: number = 20): Observable<{ total: number, resource: Order[] }> {
    const params = new HttpParams()
      .set('OrderMessageIsReadByAdmin', 'false')
      .set('pageSize', pageSize.toString());
  
    return this.http.get<Order[]>(this._baseLink, { observe: 'response', params: params }).pipe(
      map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        };
      })
    );
  }

  public getOrdersWithUnreadClientMessages(pageSize: number = 20): Observable<{ total: number, resource: Order[] }> {
    const params = new HttpParams()
      .set('OrderMessageIsReadByClient', 'false')
      .set('pageSize', pageSize.toString());
  
    return this.http.get<Order[]>(this._baseLink, { observe: 'response', params: params }).pipe(
      map(response => {
        return {
          total: this.parseResourceCountHeader(response.headers),
          resource: response.body ?? []
        };
      })
    );
  }

  //-------------------------------

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
