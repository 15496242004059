<div [@slideDown]="showNotices ? 'visible' : 'hidden'" class="validation-problems notices"
    data-testid="validation-notices-text">
    <div class="flex-container-space-between" [class.pointer]="collapsible" (click)="this.collapseNotices = !this.collapseNotices;">
        <span class="heading">
            <mat-icon class="icon">info</mat-icon>
            <h4>Notices</h4>
            <h4 *ngIf="collapsible">: {{ getNotices().length }}</h4>
        </span>
        <lib-disclosure-indicator [isExpanded]="!collapseNotices" *ngIf="collapsible"></lib-disclosure-indicator>
    </div>
    <div class="list-container" [@slideDown]="shouldShowNotices() ? 'visible' : 'hidden'">
        <ul>
            <li *ngFor="let notice of getNotices()" data-testid="validation-notice-item">{{ getMessage(notice) }}</li>
        </ul>
    </div>
</div>

<div [@slideDown]="showWarnings ? 'visible' : 'hidden'" class="validation-problems warnings"
    data-testid="validation-warnings-text">
    <div class="flex-container-space-between" [class.pointer]="collapsible" (click)="this.collapseWarnings = !this.collapseWarnings">
        <span class="heading">
            <mat-icon class="icon">warning</mat-icon>
            <h4>Warnings</h4>
            <h4 *ngIf="collapsible">: {{ getWarnings().length }}</h4>
        </span>
        <lib-disclosure-indicator [isExpanded]="!collapseWarnings" *ngIf="collapsible"></lib-disclosure-indicator>
    </div>
    <div class="list-container" [@slideDown]="shouldShowWarnings() ? 'visible' : 'hidden'">
        <ul>
            <li *ngFor="let warning of getWarnings()" data-testid="validation-warning-item">{{ getMessage(warning) }}
            </li>
        </ul>
    </div>
</div>

<div [@slideDown]="showErrors ? 'visible' : 'hidden'" class="validation-problems errors"
    data-testid="validation-errors-text">
    <div class="flex-container-space-between" [class.pointer]="collapsible" (click)="this.collapseErrors = !this.collapseErrors">
        <span class="heading">
            <mat-icon class="icon">error</mat-icon>
            <h4>Errors</h4>
            <h4 *ngIf="collapsible">: {{ getErrors().length }}</h4>
        </span>
        <lib-disclosure-indicator [isExpanded]="!collapseErrors" *ngIf="collapsible"></lib-disclosure-indicator>
    </div>
    <div class="list-container" [@slideDown]="shouldShowErrors() ? 'visible' : 'hidden'">
        <ul>
            <li *ngFor="let error of getErrors()" data-testid="validation-error-item">{{ getMessage(error) }}</li>
        </ul>
    </div>
</div>