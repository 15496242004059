import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Party } from '../../interfaces/parties/party';
import { ePartyType } from '../../enums';
import { AddressFormComponent } from '../address-form/address-form.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { HandlePartycomponent } from '../handle-party/handle-party.component';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { SelectOption } from '../../interfaces/select-option';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';

@Component({
  selector: 'lib-handle-debtor',
  templateUrl: './handle-debtor.component.html',
  styleUrls: ['./handle-debtor.component.css']
})
export class HandleDebtorComponent extends HandlePartycomponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AddressFormComponent) addressFormComponent!: AddressFormComponent;

  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  // Debtor to bind to fields and post to RegHub when pushing add button.
  @Input() debtor: Party = this.emptyEntityService.getEmptyParty(ePartyType.individualDebtor, this.order?.id ?? '');
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;

  @Output() debtorSavedEvent = new EventEmitter<Party>();
  @Output() debtorRemovedEvent = new EventEmitter<Party>();
  @Output() debtorFormValueChangedEvent = new EventEmitter<Party>();

  private onDestroy$ = new Subject<void>();

  protected debtorDetails!: FormGroup;
  protected contactDetails!: FormGroup;

  individualDebtor = ePartyType.individualDebtor;
  businessDebtor = ePartyType.businessDebtor;
  displayIndividualFields: boolean = false;
  displayBusinessFields: boolean = false;

  firstNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  middleNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  lastNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  busNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  dateOfBirthError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  emailError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  generationError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  generations: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private emptyEntityService: EmptyEntityService,
    private enumeratedTypesService: EnumeratedTypesRepositoryService) {
    super();
  }

  private initForms() {
    if (this.debtor.partyTypeID.toString() == "IndividualDebtor") {
      this.debtor.partyTypeID = this.individualDebtor;
    }

    if (this.debtor.partyTypeID.toString() == "BusinessDebtor") {
      this.debtor.partyTypeID = this.businessDebtor;
    }

    this.contactDetails = this.formBuilder.group({
      email: [this.debtor.contactDetails?.email]
    })

    this.debtorDetails = this.formBuilder.group({
      id: [this.debtor.id],
      partyTypeID: [this.debtor.partyTypeID],
      firstName: [this.debtor.firstName],
      middleName: [this.debtor.middleName],
      lastName: [this.debtor.lastName],
      busName: [this.debtor.busName],
      dateOfBirth: [this.debtor.dateOfBirth],
      contactDetails: this.contactDetails
    });


    if (this.uiConfiguration.showEstate) {
      this.debtorDetails.addControl('isEstate', new FormControl(this.debtor.isEstate));
    }

    if(this.uiConfiguration.showGeneration) {
      this.enumeratedTypesService.getGenerations().subscribe(generations => {
        this.generations = generations;
        //For some reason converting an enum | null to a number just like doesn't work. We'll need to figure out a way to 
        //populate the initial value but I've spent way to long on this right now
        this.debtorDetails.addControl('generationID', new FormControl(this.debtor.generationID));
      });
    }

    this.displayIndividualFields = (this.debtorDetails.get('partyTypeID')!.value == ePartyType.individualDebtor || this.debtorDetails.get('partyTypeID')!.value == "IndividualDebtor");
    this.displayBusinessFields = (this.debtorDetails.get('partyTypeID')!.value == ePartyType.businessDebtor || this.debtorDetails.get('partyTypeID')!.value == "BusinessDebtor");
  }

  private initAddressForms() {
    if (this.addressFormComponent.addressDetails) {
      this.contactDetails.setControl('address', this.addressFormComponent.addressDetails);
    }
  }

  private initSubscriptions() {
    this.debtorDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.debtorFormValueChangedEvent.emit(this.getParty(this.debtorDetails)));
    this.debtorDetails.get('partyTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayIndividualFields = (val == ePartyType.individualDebtor || val == "IndividualDebtor");
        this.displayBusinessFields = (val == ePartyType.businessDebtor || val == "BusinessDebtor");
      });

    if(this.errors$) {
      this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
    }
  }

  ngOnInit(): void {
    this.initForms()
  }

  ngAfterViewInit(): void {
    this.initAddressForms();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFields() {
    this.debtor = this.emptyEntityService.getEmptyParty(ePartyType.individualDebtor, this.order?.id ?? '');

    this.displayIndividualFields = this.debtor.partyTypeID == ePartyType.individualDebtor;
    this.displayBusinessFields = this.debtor.partyTypeID == ePartyType.businessDebtor;

    this.debtorDetails.reset(this.debtor);
    this.debtorDetails.setErrors(null);
    this.debtorDetails.markAsPristine();
    this.debtorDetails.markAsUntouched();
    this.debtorDetails.updateValueAndValidity();
  }

  removeDebtor() {
    this.debtorRemovedEvent.emit(this.debtorDetails.value);
  }

  saveDebtor() {
    if (this.displayBusinessFields) {
      // if business debtor, don't save individual fields
      this.debtorDetails.get('firstName')?.setValue(null);
      this.debtorDetails.get('middleName')?.setValue(null);
      this.debtorDetails.get('lastName')?.setValue(null);
      this.debtorDetails.get('dateOfBirth')?.setValue(null);
    } else {
      // if individual debtor, don't save business fields
      this.debtorDetails.get('busName')?.setValue(null);
    }

    this.debtorSavedEvent.emit(this.getParty(this.debtorDetails));
    this.clearFields();
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if (!errors) {
      return;
    }

    this.addressFormComponent.pushErrors(errors);

    this.firstNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/firstname')).at(0)?.userFriendlyMessage);
    this.middleNameError$.next(errors?.filter(error => error.path.includes('/middlename')).at(0)?.userFriendlyMessage);
    this.lastNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/lastname')).at(0)?.userFriendlyMessage);
    this.busNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/busname')).at(0)?.userFriendlyMessage);
    this.dateOfBirthError$.next(errors?.filter(error => error.path.includes('/dateofbirth')).at(0)?.userFriendlyMessage);
    this.emailError$.next(errors?.filter(error => error.path.includes('/email')).at(0)?.userFriendlyMessage);
    this.generationError$.next(errors?.filter(error => error.path.includes('/generation')).at(0)?.userFriendlyMessage);
  }

  setParty(party: Party) {
    this.debtor = party;

    this.initForms()
    this.addressFormComponent.address = this.debtor.contactDetails?.address;

    this.addressFormComponent.initForm();
    this.addressFormComponent.initJurisdictionsForm();

    this.initAddressForms();
    this.initSubscriptions();
  }
}
