import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ListComponent } from '../list-component';
import { Order } from '../../interfaces/orders/order';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';
import { MessagesStateService } from '../../services/messages-state-service/messages-state.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MessageListComponent extends ListComponent<Order> implements OnInit {
  protected isAdmin: boolean = false;
  protected _expandedOrder: Order | null = null;

  displayedColumns: string[] = [
    'referenceNumber', 
    'orderTypeID', 
    'orderStatusTypeID',
    'country',
    'jurisdiction',
    'messageDate'
  ];

  columnsToDisplayWithView = [...this.displayedColumns, 'view', 'indicator'];

  constructor(
    repo: OrderRepositoryService,
    private route: ActivatedRoute,
    private router: Router,
    private messagesState: MessagesStateService) {
      super(repo)
    }

  ngOnInit(): void {
    this.isAdmin = this.route.snapshot.data['isAdmin'];

    // only need to subscribe to this for Client, Admin is constantly updated
    if (!this.isAdmin) {
      this.repo.resource$.subscribe((orders: Order[]) => {    

        if (this.router.url === '/messages') {
          // if we are on messages, set count accordingly
          this.messagesState.setUnreadMessageCount(orders.length)
        } else {
          // if we are loading orders from somewhere else and there is a customer visible order in
          // the returned list, update messages to be flagged as unread, but don't remove the flag
          // as paginated results may not have unread messages.
          const hasUnread = orders.some(order => 
            order.orderMessages?.some(m => m.isCustomerVisible && !m.isReadByCustomer)
          );
          
          if (hasUnread) {
            this.messagesState.setUnreadMessageCount(1);
          }
        }

        const hasUnread = orders.some(order => 
          order.orderMessages?.some(m => m.isCustomerVisible && !m.isReadByCustomer)
        );
        
        if (hasUnread) {
          this.messagesState.setUnreadMessageCount(1);
        }
      });
    }

    // this.getMessages();
  }

  // protected getMessages() {
  //   // var params = new HttpParams()
  //   //   .set('isVisible', true)
  //   //   .set('sortColumn', 'OrderStatusUpdatedDate')
  //   //   .set('sortDirection', 'desc');

  //   // if (this.isAdmin) {
  //   //   params = params.set('orderMessageIsReadByAdmin', false)
  //   // } else {
  //   //   params = params.set('orderMessageIsReadByClient', false)
  //   // }

  //   this.repo.getFirstPage(params);
  // }

  protected viewOrder(order: Order, newTab: boolean) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['orders', order.id])
      );
  
      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['orders', order.id])
    }
  }

  getLatestMessage(order: Order): string {
    return String(order.orderMessages?.[order.orderMessages.length - 1]?.message || '');
  }

  getMessageDate(order: Order): Date {
    if (order.orderMessages!.length > 0) {
      return order.orderMessages![order.orderMessages!.length - 1]!.added;
    }
    return new Date();
  }
}
