export class UserSession {
    sid: string;
    sub: string;
    authTime: string;
    idp: string;
    name: string;
    logoutUrl: string;
    sessionExpiresIn: number;

    constructor() {
        this.sid = "";
        this.sub = "";
        this.authTime = "";
        this.idp = "";
        this.name = "";
        this.logoutUrl = "";
        this.sessionExpiresIn = 0;
    }
}