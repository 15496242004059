export interface AutomationMonitor {
    id: string;
    added: string;
    automationType: AutomationType
    isFailed: boolean;
    isWarning: boolean;
    automationName: string;
    lastSuccessfulRunDate: string;
    scheduledFrequency: number;
    warningFrequency: number;
    currentQueueSize: number;
    maxQueueSize: number;
    warningQueueSize: number;
    isAutomationEnabled: boolean;
}

export interface AutomationType {
    id: AutomationTypeID,
    name: string
}

export enum AutomationTypeID {
    Queue = 1,
    Scheduled = 2
}