import { Injectable, Inject } from '@angular/core';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Order } from '../../interfaces/orders/order';
import { OrderUIConfiguration } from '../../interfaces/ui-configurations/order-ui-configuration';
import { eOrderType } from '../../enums';
import { UiConfigurationRequest } from '../../interfaces/ui-configurations/ui-configuration-request';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UIConfigurationService {
    public uiConfiguration$ = new BehaviorSubject<OrderUIConfiguration | undefined>(undefined);

    protected _baseLink: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${config.rootUrl}/UIConfigurations`;
    }

    public getOrderUIConfigurations(orderType: eOrderType, jurisdictions: string[]): Observable<OrderUIConfiguration[]> {
        let params = new HttpParams();

        jurisdictions.forEach(j => params = params.append('jurisdictions', j));

        return this.http.get<OrderUIConfiguration[]>(`${this._baseLink}/${orderType}`, { params: params, observe: 'body' });
    }

    public getOrderUIConfigurationByValues(values: UiConfigurationRequest): Observable<OrderUIConfiguration> {
        let params = new HttpParams();

        if (values.orderTypeID == eOrderType.amendment && values?.originalQCFormTypeID) {
            params = params.append('qcFormTypeID', values.originalQCFormTypeID);
        } else if (values.qcFormTypeID) {
            params = params.append('qcFormTypeID', values.qcFormTypeID);
        }

        if (values.orderStatusTypeID?.toString()?.includes("Historical")) {
            params = params.append('historical', true);
        }

        if (values?.originalOrderTypeID) {
            params = params.append('originalOrderTypeID', values.originalOrderTypeID);
        }

        return this.http.get<OrderUIConfiguration>(`${this._baseLink}/${values.country}/${values.jurisdiction}/${values.orderTypeID}`, { params: params, observe: 'body' })
            .pipe(tap(config => this.uiConfiguration$.next(config)));
    }

    public getOrderUIConfiguration(order: Order): Observable<OrderUIConfiguration> {
        let params = new HttpParams();

        if (order.orderTypeID == eOrderType.amendment && order.referenceRegistrationInformation?.originalQCFormTypeID) {
            params = params.append('qcFormTypeID', order.referenceRegistrationInformation.originalQCFormTypeID);
        } else if (order.lien?.qcFormTypeID) {
            params = params.append('qcFormTypeID', order.lien.qcFormTypeID);
        }

        if (order.orderStatusTypeID.toString().includes("Historical")) {
            params = params.append('historical', true);
        }

        if (order.referenceRegistrationInformation?.originalOrderTypeID) {
            params = params.append('originalOrderTypeID', order.referenceRegistrationInformation.originalOrderTypeID);
        }

        return this.http.get<OrderUIConfiguration>(`${this._baseLink}/${order.country}/${order.jurisdiction}/${order.orderTypeID}`, { params: params, observe: 'body' })
            .pipe(tap(config => this.uiConfiguration$.next(config)));
    }
}