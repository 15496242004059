import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BusinessSearchResult } from '../../interfaces/business-search-results/business-search-result';
import { BusinessSearchResultRepositoryService } from '../../services/business-search-results/business-search-result-repository.service';
import { FiltersComponent } from '../../ui-common/filters-component';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { SelectOption } from '../../interfaces/select-option';

@Component({
  selector: 'lib-business-search-results-filter',
  templateUrl: './business-search-results-filter.component.html',
  styleUrls: ['./business-search-results-filter.component.css']
})
export class BusinessSearchResultsFilterComponent extends FiltersComponent<BusinessSearchResult> implements OnInit, OnDestroy {
  @Input() jurisdictions: SelectOption[] = [];
  @Input() types: SelectOption[] = [];
  @Input() statuses: SelectOption[] = [];
  @Input() descriptions: SelectOption[] = [];
  @Input() jurisdictionStatuses: SelectOption[] = [];
  @Input() showHiddenFilter: boolean = false;
  
  constructor(
    protected orderManager: OrderManagerService,
    businessSearchResultsRepo: BusinessSearchResultRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe) {
      super(
        businessSearchResultsRepo,
        formBuilder,
        datePipe
      )
  }

  ngOnInit() {
    var order = this.orderManager.currentOrder.value;
    this.repo.populateBaseLink([order.id])

    this._filterForm = this.formBuilder.group({
      name: [null],
      number: [null],
      jurisdiction: [null],
      jurisdictionStatus: [null],
      status: [null],
      statusDateFrom: [null],
      statusDateTo: [null],
      creationDateFrom: [null],
      creationDateTo: [null],
      companyType: [null],
      description: [null],
      showResult: [true],
      sortColumn: ["Score"],
      sortDirection: ["Asc"]
    })
  }

  ngOnDestroy(): void {
    this.repo.clearFilters();
  }
}
