import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Asset } from '../../interfaces/assets/asset';
import { SelectOption } from '../../interfaces/select-option';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { eAssetType, assetTypeLookup } from '../../enums';
import { map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { AssetUIConfiguration } from '../../interfaces/ui-configurations/asset-ui-configuration';
import { HandleAssetComponent } from '../handle-asset/handle-asset.component';

@Component({
  selector: 'lib-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AssetListComponent implements OnInit, OnDestroy{
  @ViewChildren(HandleAssetComponent) handleAssetComponents!: QueryList<HandleAssetComponent>;

  @Input() order!: Order;
  @Input() observableAssets$!: Observable<Asset[]>;
  @Input() uiConfiguration!: AssetUIConfiguration;

  @Input() isEditable: boolean = false;
  @Input() assetTypes: SelectOption[] = [];
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;

  @Output() assetAddedEvent = new EventEmitter<Asset>();
  @Output() assetUpdatedEvent = new EventEmitter<Asset>();
  @Output() assetCopiedEvent = new EventEmitter<Asset>();
  @Output() assetRemovedEvent = new EventEmitter<Asset>();
  @Output() assetFormValueChangedEvent = new EventEmitter<Asset>();

  @Input() expandedAsset: Asset | null = null;

  displayedColumns: string[] = [ 'num', 'serial-number', 'asset-type', 'year', 'make', 'model' ];
  isLoading: boolean = false;

  private assets: Asset[] = [];
  private onDestroy$ = new Subject<void>();

  constructor(private emptyEntityService: EmptyEntityService) { }

  ngOnInit(): void {
    if(!this.observableAssets$) {
      this.observableAssets$ = of(this.order.assets ?? []);
    }

    this.observableAssets$ = this.observableAssets$
      .pipe(map(assets => assets
        .filter(a => a.movedToGeneralCollateral === false)
        .sort((a, b) => a.priority - b.priority)));

    this.handlePossibleColumnChanges();

    this.observableAssets$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(assets => this.assets = assets);
  }

  onSaving(): Asset | null {
    if (this.expandedAsset) {
      const expandedComponent = this.handleAssetComponents.find(component => component.asset === this.expandedAsset);

      if (expandedComponent) {
        return expandedComponent.getAsset();;
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onEditButtonClick = (assetToEdit: Asset) => {
    if(this.isEditable) {
      this.expandedAsset = this.expandedAsset === assetToEdit ? null : assetToEdit;
    }
  }

  onDeleteButtonClick = (event: Event, assetToDelete: Asset) => {
    // emit this asset to delete
    this.assetRemovedEvent.emit(assetToDelete);
  }

  onCopyButtonClick = (event: Event, assetToCopy: Asset) => {
    const priority = this.getNewPriority();
    this.assetCopiedEvent.emit({ ...assetToCopy, priority });
  }

  onUndoButtonClick = (event: Event, assetToUndo: Asset, rowIndex: number) => {

    // emit this party to delete
    //this.partyRemovedEvent.emit(partyToUndo);

    // filter out deleted party from list of displayed parties
    //this.parties = this.parties.filter((u) => u.id !== partyToUndo.id);
    //this.order.assets = this.assets;
  }

  handleAssetUpdateEvent(asset: Asset) {
    this.assetUpdatedEvent.emit(asset);

    const index = this.order.assets!.findIndex(a => a.id === asset.id);
    if (index !== -1) {
      this.order.assets![index] = asset;
    }

    this.expandedAsset = null;

    this.handlePossibleColumnChanges();
  }

  handleAssetRemovedEvent(asset: Asset) {
    this.assetRemovedEvent.emit(asset);

    this.expandedAsset = null;

    this.handlePossibleColumnChanges();
  }

  handleAssetFormValueChangedEvent(asset: Asset) {
    this.assetFormValueChangedEvent.emit(asset);
  }

  protected addNewAsset(): void {
    const priority = this.getNewPriority();
    this.assetAddedEvent.emit(this.emptyEntityService.getEmptyAssetWithType(eAssetType.None, this.order.id ?? '', priority ?? 0))
  }

  getNumber(asset: Asset) {
    const index = this.assets.indexOf(asset);

    if(index === -1) {
      return 0;
    }

    return index + 1;
  }

  getAssetTypeString(asset: Asset) {
    const assetTypeID = assetTypeLookup[asset.assetTypeID] ?? asset.assetTypeID;

    switch (assetTypeID) {
      case eAssetType.MotorVehicle:
        return "Motor Vehicle";
      case eAssetType.Trailer:
        return "Trailer";
      case eAssetType.Boat:
        return "Boat";
      case eAssetType.MobileHome:
        return "Mobile Home";
      case eAssetType.OutboardMotor:
        return "Outboard Motor";
      case eAssetType.AircraftRegisteredInCanada:
        return "Aircraft Registered In Canada";
      case eAssetType.AircraftNonRegisteredInCanada:
        return "Aircraft Not Registered In Canada";
      case eAssetType.ManufacturedHome:
        return "Manufactured Home";
      case eAssetType.FarmVehicle:
        return "Farm Vehicle";
      case eAssetType.Motorcycle:
        return "Motorcycle";
      case eAssetType.Bus:
        return "Bus";
      case eAssetType.MiniBus:
        return "Mini Bus";
      case eAssetType.Taxi:
        return "Taxi";
      case eAssetType.AllTerrainVehicle:
        return "All Terrain Vehicle";
      case eAssetType.SnowmobilePost1988:
        return "Snowmobile Post 1988";
      case eAssetType.PassengerVehicle:
        return "Passenger Vehicle";
      case eAssetType.EmergencyVehicle:
        return "Emergency Vehicle";
      case eAssetType.CommercialVehicle:
        return "Commercial Vehicle";
      case eAssetType.Other:
        return "Other";
      case eAssetType.AircraftCanada:
        return "Aircraft Canada";
      case eAssetType.AircraftForeign:
        return "Aircraft Foreign";
      case eAssetType.AircraftAirframeRegisteredInCanada:
        return "Aircraft/Airframe Registered In Canada";
      case eAssetType.AircraftAirframeNotRegisteredInCanada:
        return "Aircraft/Airframe Not Registered In Canada";
      case eAssetType.Aircraft:
        return "Aircraft";
      case eAssetType.AircraftDOT:
        return "Aircraft DOT";
      case eAssetType.AircraftSerial:
        return "Aircraft Serial";
      case eAssetType.TrailerOrSemiTrailer:
        return "Trailer Or Semi-Trailer";
      case eAssetType.MotorHome:
        return "Motor Home";
      default:
        return "";
    }
  }

  handlePossibleColumnChanges() {
    const hasManufacturedHomeRegistrationNumber = this.order.assets?.some(asset => asset.manufacturedHomeRegistrationNumber) ?? false;
    if (hasManufacturedHomeRegistrationNumber && this.uiConfiguration.showManufacturedHomeRegistrationNumber) {
      this.displayedColumns = ['num', 'serial-number', 'asset-type', 'manufactured-home-registration-number', 'make', 'model', 'year'];
    } else {
      this.displayedColumns = ['num', 'serial-number', 'asset-type', 'make', 'model', 'year'];
    }

    if (this.uiConfiguration.showColor) {
      this.displayedColumns = this.displayedColumns.concat(['colour']);
    }

    if (this.isEditable) {
      this.displayedColumns = this.displayedColumns.concat(['actions']);
    }
  }

  private getNewPriority() {
    //Want the highest priority + 1
    let priority = this.assets
      .map(a => a.priority)
      .sort((a, b) => b - a)
      .at(0);

    if (priority === undefined || priority === 0) {
      priority = 1;
    } else {
      priority++;
    }

    return priority;
  }
}
