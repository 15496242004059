<div class="flex-container">
    <div class="flex-item">
        <h3 class="margin-top-0">Search Criteria</h3>
        <div *ngIf="isNameSearch()">
            <div class="flex-container">
                <span class="flex-item">Name:</span>
                <span class="flex-item-2">{{ order.businessSearchCriteria!.name }}</span>
            </div>
        </div>
        <div *ngIf="isNumberSearch()">
            <div class="flex-container">
                <span class="flex-item">Number:</span>
                <span class="flex-item-2">{{ order.businessSearchCriteria!.number }}</span>
            </div>
        </div>
    </div>
    <div class="flex-item" *ngIf="this.order.businessSearchCriteria != null">
        <h3 class="margin-top-0">Search Parameters</h3>
        <div class="flex-container">
            <span class="flex-item">Search Type:</span>
            <span class="flex-item-2">{{ getSearchType() }}</span>
        </div>
        <div class="flex-container" *ngIf="this.order.searchParameters?.searchJurisdictions != null">
            <span class="flex-item">Show Results From:</span>
            <span class="flex-item-2">
                {{ getJurisdictionLabels(order.searchParameters?.searchJurisdictions).join(', ') }}
            </span>
        </div>
    </div>
</div>