import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-static-notice',
  templateUrl: './static-notice.component.html',
  styleUrls: ['./static-notice.component.css']
})
export class StaticNoticeComponent {
  @Input() text: string = '';
}
