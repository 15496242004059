import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order'
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { OrderGroupsService } from '../../services/order-groups/order-groups.service';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { eOrderStatusType, eOrderType } from '../../enums';
import { Utilities } from '../../utilities';
import { ePartyType } from '../../enums';

@Component({
  selector: 'lib-multi-search-orders',
  templateUrl: './multi-search-orders.component.html',
  styleUrls: ['./multi-search-orders.component.css']
})
export class MultiSearchOrdersComponent implements OnInit, OnDestroy {
  @Input() orderUpdate$: Observable<Order> | undefined;
  protected order!: Order;
  protected orders = new BehaviorSubject<Order[]>([]);

  private onDestroy$ = new Subject<void>();

  displayedColumns: string[] = [
    'orderTypeID',
    'orderStatusTypeID',
    'criteria',
    'country',
    'jurisdiction',
    'documents',
    'view'
  ];

  constructor(
    private orderManagerService: OrderManagerService,
    private orderGroupService: OrderGroupsService,
    private router: Router) { }

  ngOnInit(): void {
    this.order = this.orderManagerService.currentOrder.value;

    if (this.order.orderGroup?.id) {
      this.orderGroupService.getOrderGroupOrders(this.order.orderGroup?.id ?? '', 100)
        .pipe(map(orders => orders
          .filter(o => o.orderTypeID !== eOrderType.assetMultiSearch)
          .sort(Utilities.sortByJurisdiction(o => o.jurisdiction))))
        .subscribe(orders => {
          this.orders.next(orders);
        });
    }

    this.orderUpdate$?.pipe(takeUntil(this.onDestroy$)).subscribe(incomingOrder => {
      const currentOrderIndex = this.orders.value.findIndex(o => o.id === incomingOrder.id);

      if (currentOrderIndex !== -1) {
        let orders = this.orders.value;
        incomingOrder.orderStatusTypeID = eOrderStatusType.complete;
        orders[currentOrderIndex] = incomingOrder;
        this.orders.next(orders);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  protected viewOrder(order: Order, newTab: boolean) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['orders', order.id])
      );

      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['orders', order.id])
    }
  }

  getSearchCriteria(order: Order): string {
    let searchCriteria = "";
    if (order?.orderType?.id == "DebtorSearch" || order?.orderType?.id == "CertifiedDebtorSearch") {
      let searchDebtor = ((order.parties?.length ?? 0) > 0) ? order.parties![0] : null;
      if (searchDebtor?.partyTypeID === ePartyType.individualDebtor) {
        searchCriteria = `${searchDebtor?.firstName ?? ""} ${searchDebtor?.middleName ?? ""} ${searchDebtor?.lastName ?? ""}`;
      }
      else {
        searchCriteria = searchDebtor?.busName ?? "";
      }
    }
    else if (order?.orderType?.id == "AssetSearch" || order?.orderType?.id == "AssetMultiSearch") {
      let searchAsset = ((this.order.assets?.length ?? 0) > 0) ? order.assets![0] : null;
      searchCriteria = searchAsset?.serialNumber ?? "";
    }

    return searchCriteria;
  }
}
