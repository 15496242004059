export interface FilterField {
    //The label to be displayed either beside the control or as a placeholder
    label?: string;
    //The name of the resource parameter to be passed into the query string
    //In the case of FieldTypes.jurisdiction, this will be the name of the form
    resourceParameterName: string;
    //The type of control to display for the field
    type: FieldTypes;
    //Optional extra options for checkbox
    checkboxOptions?: {
        //Generates a second checkbox next to the first, to allow filtering for the opposite condition
        //Eg. Active, Inactive
        includeInverse?: boolean;
        //The label for the second checkbox. Defaults to the label of the first if non provided
        inversionLabel?: string;
    },
    //Optional extra options for dropdownlists
    dropdownListOptions?: {
        //Allow multi select for this dropdown list
        multi?: boolean;
        //The dropdownlist options
        options?: {
            //The label to be displayed for this option
            label: string,
            //The value that will be passed through into the query string
            value: string | number | boolean
        }[];
    },
    datepickerOptions?: {
        //For a range date picker, supply the after date resource parameter in resourceParameterName, and the before one in the range object
        range?: {
            beforeDateResourceParameterName: string;
        }
    }
}


export enum FieldTypes {
    text,
    checkbox,
    dropdown,
    datepicker,
    jurisdiction
}