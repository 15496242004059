import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[libReplaceCharacter]'
})
export class ReplaceCharacterDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;

    // Replace soft hyphen (­) with a normal hyphen (-)
    const updatedValue = inputElement.value.replace(/\u00AD/g, '-');

    if (updatedValue !== inputElement.value) {
      // Update the input field value
      inputElement.value = updatedValue;

      // Update the form control value
      if (this.control && this.control.control) {
        this.control.control.setValue(updatedValue, { emitEvent: true });
      }
    }
  }
}