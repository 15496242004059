import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Lien } from '../../interfaces/liens/lien';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { LienDetailsUIConfiguration } from '../../interfaces/ui-configurations/lien-details-ui-configuration';
import { eOrderStatusType, eOrderType } from '../../enums';

@Component({
    selector: 'lib-lien-details',
    templateUrl: './lien-details.component.html',
    styleUrls: ['./lien-details.component.css']
})
export class LienDetailsComponent {

    @Input() uiConfiguration!: LienDetailsUIConfiguration;
    order!: Order;
    lien!: Lien | null | undefined;

    private unsubscribe$ = new Subject<void>();

    protected expectedExpiryDate: Date | undefined;
    protected shouldShowExpectedExpiry: boolean | undefined;

    constructor(private orderManager: OrderManagerService) { }

    ngOnInit(): void {
        this.orderManager.currentOrder
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(order => {
                this.order = order;
                this.lien = order.lien;

                this.calculateExpectedExpiryDate();
            });
    }

    ngOnDestroy(): void {
        // Signal all subscriptions to complete
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected getRIN(): string {
        if (this.order.lien?.rin) {
            return this.order.lien?.rin ?? '';
        } else if (this.order.lien?.systemGeneratedRIN) {
            return 'System Generated';
        } else if (this.order.lien?.noRIN) {
            return 'No RIN';
        } else {
            // this shouldn't happen
            return '';
        }
    }

    protected isBasic(): boolean {
        return (this.order.orderTypeID === eOrderType.basicDischarge ||
            this.order.orderTypeID === eOrderType.basicRenewal);
    }

    protected isHistorical(): boolean {
        return this.order.orderStatusTypeID === eOrderStatusType.historicalDraft ||
            this.order.orderStatusTypeID === eOrderStatusType.historical;
    }

    protected getExpiryDateHeader(): string {
        return this.isHistorical() ? 'Expiry Date:' : 'Requested Expiry Date:';
    }

    calculateExpectedExpiryDate() {
        this.shouldShowExpectedExpiry = this.order.orderStatusTypeID == eOrderStatusType.draft &&
            this.uiConfiguration.showSigningDate &&
            (this.lien?.term != null && this.lien?.term != undefined) &&
            (this.lien?.signingDate != null && this.lien?.signingDate != undefined);

        if (this.shouldShowExpectedExpiry) {
            const signingDate = new Date(this.lien!.signingDate!);
            this.expectedExpiryDate = new Date(
                signingDate.getFullYear() + this.lien!.term!,
                signingDate.getMonth(),
                signingDate.getDate());
        }
    }
}