<div class="margin-bottom-5">
    <div class="flex-container" *ngIf="uiConfiguration.showQCFormTypeID">
        <span class="flex-item-3">Form Type:</span>
        <span class="flex-item-4" data-testid="lien-details-form-type-text">{{ lien?.qcFormTypeID }}</span>
    </div>
    <div *ngIf="(uiConfiguration.showTerm || uiConfiguration.showInfinityTerm) && (lien?.term || lien?.isInfiniteTerm)" class="flex-container">
        <span class="flex-item-3">Term:</span>
        <span class="flex-item-4" data-testid="lien-details-term-text">{{ lien?.isInfiniteTerm ? "INFINITY" : order.lien?.term }}</span>
    </div>
    <div *ngIf="uiConfiguration.showTerm && uiConfiguration.registryDefinedTerm" class="flex-container">
        <span class="flex-item-3">Term:</span>
        <span class="flex-item-4" data-testid="lien-details-term-text">{{ uiConfiguration.registryDefinedTerm }}</span>
    </div>
    <div *ngIf="uiConfiguration.showExpiryDate && !lien?.term && !lien?.isInfiniteTerm" class="flex-container">
        <span class="flex-item-3">{{ getExpiryDateHeader() }}</span>
        <span class="flex-item-4" data-testid="lien-details-expiry-date-text">{{ lien?.expiryDate | localizedDate: 'yyyy-MM-dd' }}</span>
    </div>
    <div *ngIf="uiConfiguration.showAmount && lien?.amount" class="flex-container">
        <span class="flex-item-3">Amount:</span>
        <span class="flex-item-4" data-testid="lien-details-amount-text">{{ lien?.amount | currency }}</span>
    </div>
    <div class="flex-container" *ngIf="(uiConfiguration.showSigningCity ||
                                    uiConfiguration.showSigningJurisdiction ||
                                    uiConfiguration.showSigningCountry) && 
                                    (lien?.signingCity || 
                                    lien?.signingJurisdiction || 
                                    lien?.signingCountry) ">
        <span class="flex-item-3">Signing Location:</span>
        <span class="flex-item-4" data-testid="lien-details-signing-location-text">{{ lien?.signingCity }} {{ lien?.signingJurisdiction }} {{ lien?.signingCountry }}
        </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSigningDate && lien?.signingDate">
        <span class="flex-item-3">Signing Date:</span>
        <span class="flex-item-4" data-testid="lien-details-signing-date-text">{{ lien?.signingDate | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="this.shouldShowExpectedExpiry">
        <span class="flex-item-3">Expiry Date:</span>
        <span class="flex-item-4" data-testid="lien-details-expected-expiry-date-text">{{ this.expectedExpiryDate| localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSurrenderDate && lien?.surrenderDate">
        <span class="flex-item-3">Surrender Date:</span>
        <span class="flex-item-4" data-testid="lien-details-surrender-date">{{ lien?.surrenderDate | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div *ngIf="uiConfiguration.showStillHavePossession" class="flex-container">
        <span class="flex-item-3">Still Have Possession:</span>
        <span class="flex-item-4" data-testid="lien-details-possession-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.stillHavePossession ?? false"></mat-checkbox></span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showVehicleReleasedDate && lien?.vehicleReleasedDate">
        <span class="flex-item-3">Vehicle Released Date:</span>
        <span class="flex-item-4" data-testid="lien-details-vehicle-released-date-text">{{ lien?.vehicleReleasedDate | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showWorkLastCompletedDate && lien?.workLastCompletedDate">
        <span class="flex-item-3">Work Last Completed Date:</span>
        <span class="flex-item-4" data-testid="lien-details-work-last-completed-text">{{ lien?.workLastCompletedDate | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div *ngIf="uiConfiguration.showProvidedPartsOrAccessories" class="flex-container">
        <span class="flex-item-3">Parts Provided or Accessories:</span>
        <span class="flex-item-4" data-testid="lien-details-parts-provided-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.providedPartsOrAccessories ?? false"></mat-checkbox></span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showPartsProvidedDate && lien?.partsProvidedDate">
        <span class="flex-item-3">Parts Provided Date:</span>
        <span class="flex-item-4" data-testid="lien-details-parts-provided-date-text">{{ lien?.partsProvidedDate | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showServiceLastRendered && lien?.serviceLastRendered">
        <span class="flex-item-3">Service Last Rendered:</span>
        <span class="flex-item-4" data-testid="lien-details-service-last-rendered-text">{{ lien?.serviceLastRendered | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showDateOfLien && lien?.dateOfLien">
        <span class="flex-item-3">Date of Lien:</span>
        <span class="flex-item-4" data-testid="lien-details-date-of-lien-text">{{ lien?.dateOfLien | localizedDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showRIN">
        <span class="flex-item-3">RIN:</span>
        <span class="flex-item-4" data-testid="lien-details-rin-text">{{ getRIN() }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showFileNumber && isBasic()">
        <span class="flex-item-3">File Number:</span>
        <span class="flex-item-4" data-testid="lien-details-file-number-text">{{ lien?.fileNumber }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSumOfHypothec && lien?.sumOfHypothec">
        <span class="flex-item-3">Sum of Hypothec:</span>
        <span class="flex-item-4" data-testid="lien-details-sum-of-hypothetic-text">{{ lien?.sumOfHypothec }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showAuthorityText">
        <span class="flex-item-3">Authority Text:</span>
        <span class="flex-item-4" data-testid="lien-details-authority-text">{{ lien?.authorityText }}</span>
    </div>
    <div *ngIf="uiConfiguration.showPerfectionInAnotherJurisdiction" class="flex-container">
        <span class="flex-item-3">Perfection in Another Jurisdiction:</span>
        <span class="flex-item-4" data-testid="lien-details-perfection-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.perfectionInAnotherJurisdiction ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showPurchaseMoneySecurityInterest" class="flex-container">
        <span class="flex-item-3">Purchase Money Security Interest:</span>
        <span class="flex-item-4" data-testid="lien-details-purchase-money-security-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.purchaseMoneySecurityInterest ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showTrustIndenture" class="flex-container">
        <span class="flex-item-3">Trust Indenture:</span>
        <span class="flex-item-4" data-testid="lien-details-trust-ind-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.trustIndenture ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showReceiverAppointment" class="flex-container">
        <span class="flex-item-3">Receiver Appointment:</span>
        <span class="flex-item-4" data-testid="lien-details-receiver-appointment-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.receiverAppointment ?? false"></mat-checkbox></span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showMaturityDate && order.lien?.maturityDate ?? false">
        <span class="flex-item-3">Maturity Date:</span>
        <span class="flex-item-4" data-testid="lien-details-maturity-date-text">{{ order.lien?.maturityDate | localizedDate: "yyyy-MM-dd" }}</span>
    </div>
    <div *ngIf="uiConfiguration.showMaturityDate" class="flex-container">
        <span class="flex-item-3">No Fixed Maturity Date:</span>
        <span class="flex-item-4" data-testid="lien-details-no-fixed-maturity-date-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.noFixedMaturityDate ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showConsumerGoods" class="flex-container">
        <span class="flex-item-3">Consumer Goods:</span>
        <span class="flex-item-4" data-testid="lien-details-consumer-goods-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.consumerGoods ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showInventory" class="flex-container">
        <span class="flex-item-3">Inventory:</span>
        <span class="flex-item-4" data-testid="lien-details-inventory-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.inventory ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showEquipment" class="flex-container">
        <span class="flex-item-3">Equipment:</span>
        <span class="flex-item-4" data-testid="lien-details-equipment-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.equipment ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showAccounts" class="flex-container">
        <span class="flex-item-3">Accounts:</span>
        <span class="flex-item-4" data-testid="lien-details-accounts-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.accounts ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showOther" class="flex-container">
        <span class="flex-item-3">Other:</span>
        <span class="flex-item-4" data-testid="lien-details-other-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.other ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showMVIncluded" class="flex-container">
        <span class="flex-item-3">MV Included:</span>
        <span class="flex-item-4" data-testid="lien-details-mv-included-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.mvIncluded ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showCautionFiling" class="flex-container">
        <span class="flex-item-3">Caution Filing:</span>
        <span class="flex-item-4" data-testid="lien-details-caution-filing-checkbox"><mat-checkbox disabled="true"
                [checked]="order.lien?.cautionFiling ?? false"></mat-checkbox></span>
    </div>
</div>
<div *ngIf="uiConfiguration.showAdditionalInformation && (order.lien?.additionalInformation || order.lien?.registeredAdditionalInformation)" class="flex-item">
    <div class="bold">Additional Information:</div>
    <div class="preserve-line-breaks" data-testid="lien-details-additional-information-text">{{ uiConfiguration.showRegisteredAdditionalInformation ? order.lien?.registeredAdditionalInformation : order.lien?.additionalInformation }}</div>
</div>
<div *ngIf="uiConfiguration.showCourtOrder && order.lien?.courtOrder" class="flex-item">
    <div class="bold">Court Order:</div>
    <div class="preserve-line-breaks" data-testid="lien-details-court-order-text">{{ order.lien?.courtOrder }}</div>
</div>
<div *ngIf="uiConfiguration.showOtherChanges && order.lien?.otherChanges" class="flex-item">
    <div class="bold">Other Changes:</div>
    <div class="preserve-line-breaks" data-testid="lien-details-other-changes-text">{{ order.lien?.otherChanges }}</div>
</div>