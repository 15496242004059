import { Component, QueryList, ViewChildren } from '@angular/core';
import { PartyListComponent } from '../party-list/party-list.component';
import { DatePipe } from '@angular/common';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { ePartyType } from '../../enums';
import { HandleDebtorComponent } from '../handle-debtor/handle-debtor.component';

@Component({
  selector: 'lib-debtor-list',
  templateUrl: './debtor-list.component.html',
  styleUrls: ['./debtor-list.component.css'],
  providers: [DatePipe]
})
export class DebtorListComponent extends PartyListComponent {
  @ViewChildren(HandleDebtorComponent) handleDebtorComponents!: QueryList<HandleDebtorComponent>;

  constructor(
    datePipe: DatePipe,
    private emptyEntityService: EmptyEntityService) {
    super(datePipe);
  }

  protected addNewDebtor() {
    let newParty = this.emptyEntityService.getEmptyParty(ePartyType.individualDebtor, this.order?.id ?? '');
    this.partyAddedEvent.emit(newParty);
  }
}
