import { UserGroup } from "./user-groups/user-group";

export interface UserGroupAccess{
  id: string;
  //added: Date
  userGroupID: string
  hasAccessToUserGroupID: string
  hasAccessToUserGroup?: UserGroup
  canRead: boolean
  canWrite: boolean
}

export class EditableUserGroupAccess implements UserGroupAccess{
  id: string;
  //added: Date
  userGroupID: string
  hasAccessToUserGroupID: string
  hasAccessToUserGroup?: UserGroup
  canRead: boolean
  canWrite: boolean
  isEditing: boolean
  isSaving: boolean
  isDeleting: boolean

  constructor(id: string,
              userGroupID: string,
              hasAccesstoUserGroupID: string,
              hasAccessToUserGroup: UserGroup | undefined,
              canRead: boolean,
              canWrite: boolean) {
    this.id = id;
    this.userGroupID = userGroupID;
    this.hasAccessToUserGroup = hasAccessToUserGroup;
    this.hasAccessToUserGroupID = hasAccesstoUserGroupID;
    this.canRead = canRead;
    this.canWrite = canWrite;
    this.isEditing = false;
    this.isSaving = false;
    this.isDeleting = false;
  }
}