import { Component, Input } from '@angular/core';
import { base64StringToBlob } from 'blob-util';
import { BatchDocumentsRepositoryService } from '../../services/batch-documents/batch-documents-repository-services';

@Component({
    selector: 'lib-batch-document-download',
    templateUrl: './batch-document-download.component.html',
    styleUrls: ['./batch-document-download.component.css']
})
export class BatchDocumentDownloadComponent {
    @Input({ required: true }) documentID!: string | null | undefined;
    @Input() documentName: string = "Document"

    constructor(private documentsRepo: BatchDocumentsRepositoryService) { }

    downloadDocument() {
        if (this.documentID) {
            //Get Document
            this.documentsRepo.getDocument(this.documentID!)
                .subscribe(result => {
                    const documentType = `application/${result?.documentTypeID ?? "pdf" }`.toLowerCase();
                    const blob = base64StringToBlob(result.base64, documentType);
                    const blobURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobURL;
                    link.download = result.fileName; // set a name for the file
                    link.click();
                });
        }
    }
}