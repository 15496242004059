import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FieldTypes, FilterField } from './filterfield.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {
  @Output() filter: EventEmitter<HttpParams> = new EventEmitter();
  @Input() filterFields: FilterField[] = [];

  _textboxFields: FilterField[] = [];
  _checkboxFields: FilterField[] = [];
  _dropdownFields: FilterField[] = [];
  _datepickerFields: FilterField[] = [];
  _jurisdictionField: FilterField | null = null;

  _filterForm: FormGroup = this.formBuilder.group({});
  _initialValues: any;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.setupFields();
  }

  ngOnChanges(): void {
    this.setupFields();
  }

  onFilter() {
    let params = new HttpParams();

    for(const field of this.filterFields.filter(field => 
        field.type !== FieldTypes.dropdown && 
        field.type !== FieldTypes.checkbox &&
        field.type !== FieldTypes.jurisdiction &&
        field.type !== FieldTypes.datepicker)) {
      const control = this._filterForm.get(field.resourceParameterName);
      const controlValue = control?.value;

      if (controlValue) {
        params = params.set(field.resourceParameterName, controlValue);
      }
    }

    for(const field of this.filterFields.filter(field => field.type === FieldTypes.dropdown)) {
      const control = this._filterForm.get(field.resourceParameterName);
      const controlValue = control?.value;

      if (controlValue) {
        if (field.dropdownListOptions?.multi ?? false) {
          for (const value of controlValue) {
            params = params.append(field.resourceParameterName, value);
          }
        } else {
          params = params.set(field.resourceParameterName, controlValue);
        }
      }
    }

    for(let i = 0; i < this.filterFields.length; i++) {
      const field = this.filterFields[i];

      if(field.type !== FieldTypes.checkbox) {
        continue;
      }

      const control = this._filterForm.get(field.resourceParameterName);
      const controlValue = control?.value;

      if(field.checkboxOptions?.includeInverse) {
        const inverseControl = this._filterForm.get(field.resourceParameterName + '_inverse');
        const inverseControlValue = inverseControl?.value;

        if (controlValue && !inverseControlValue) {
          params = params.set(field.resourceParameterName, true);
        } else if(!controlValue && inverseControlValue) {
          params = params.set(field.resourceParameterName, false);
        }

        i++;
      } else {
        if (controlValue) {
          params = params.set(field.resourceParameterName, controlValue);
        }
      }
    }

    for(const field of this.filterFields.filter(field => field.type === FieldTypes.datepicker)) {
      const control = this._filterForm.get(field.resourceParameterName);
      const controlValue = control?.value;

      if (controlValue) {
        params = params.set(field.resourceParameterName, this.datePipe.transform(controlValue, 'yyyy-MM-dd') ?? '');
      }

      if(field.datepickerOptions?.range) {
        const beforeControl = this._filterForm.get(field.datepickerOptions.range.beforeDateResourceParameterName);
        const beforeControlValue = beforeControl?.value;

        if(beforeControlValue) {
          params = params.set(field.datepickerOptions.range.beforeDateResourceParameterName, this.datePipe.transform(beforeControlValue, 'yyyy-MM-dd') ?? '');
        }
      }
    }

    if(this._jurisdictionField) {
      const control = this._filterForm.get(this._jurisdictionField.resourceParameterName);
      const controValue = control?.value;

      if(controValue) {
        if(controValue.country) {
          params = params.set('country', controValue.country);
        }

        if(controValue.jurisdiction) {
          params = params.set('jurisdiction', controValue.jurisdiction);
        }
      }
    }

    if(params.keys().length > 0) {
      this.filter.emit(params);
    }
  }

  onClear() {
    if(this._filterForm.dirty) {
      this.filter.emit(new HttpParams());
    }

    this._filterForm.reset();
  }

  onClearInitial() {
    if(this._filterForm.dirty) {
      this.filter.emit(new HttpParams());
    }

    this._filterForm.reset(this._initialValues);
  }

  private setupFields() {
    this._textboxFields = this.filterFields.filter(field => field.type === FieldTypes.text);
    this._dropdownFields = this.filterFields.filter(field => field.type === FieldTypes.dropdown);
    this._datepickerFields = this.filterFields.filter(field => field.type === FieldTypes.datepicker);

    this._checkboxFields = this.filterFields.filter(field => field.type === FieldTypes.checkbox);
    let inverseCheckBoxes: FilterField[] = [];

    const inverse = '_inverse';
    for(const checkbox of this._checkboxFields) {
      if(checkbox.checkboxOptions?.includeInverse) {
        inverseCheckBoxes.push({
          resourceParameterName: `${checkbox.resourceParameterName}${inverse}`,
          type: checkbox.type,
          label: checkbox.checkboxOptions?.inversionLabel ?? checkbox.label,
          checkboxOptions: checkbox.checkboxOptions
        })
      }
    }

    for(const checkbox of inverseCheckBoxes) {
      let index = this.filterFields.findIndex(field => `${field.resourceParameterName}${inverse}` === checkbox.resourceParameterName);
      this.filterFields.splice(index + 1, 0, checkbox);
    }
    
    this._checkboxFields = this.filterFields.filter(field => field.type === FieldTypes.checkbox);
  
    const formGroupConfig: { [key: string]: any } = {};

    this.filterFields.filter(field => field.type !== FieldTypes.jurisdiction && field.type !== FieldTypes.datepicker).forEach(field => {
      const initialValue = field.type === FieldTypes.checkbox ? false : null;
      formGroupConfig[field.resourceParameterName] = new FormControl(initialValue);
    });

    this.filterFields.filter(field => field.type === FieldTypes.datepicker).forEach(field => {
      formGroupConfig[field.resourceParameterName] = new FormControl(null);

      if(field.datepickerOptions?.range) {
        formGroupConfig[field.datepickerOptions.range.beforeDateResourceParameterName] = new FormControl(null);
      }
    })

    this._jurisdictionField = this.filterFields.find(field => field.type === FieldTypes.jurisdiction) ?? null;
    if(this._jurisdictionField) {
      formGroupConfig[this._jurisdictionField.resourceParameterName] = new FormGroup({});
    }
  
    this._filterForm = this.formBuilder.group(formGroupConfig);

    this._initialValues = this._filterForm.value;
  }
}
