import { Inject, Injectable } from '@angular/core';
import { BusinessSearchResult } from '../../interfaces/business-search-results/business-search-result';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { BusinessSearchResultsFilters } from '../../interfaces/business-search-results/business-search-results-filters';

@Injectable({
    providedIn: 'root'
})
export class BusinessSearchResultRepositoryService extends PaginatedRepository<BusinessSearchResult> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(http, messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Orders/[0]/BusinessSearchResults`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getBusinessSearchResults = (orderID: string) => {
        var urlSlug = `/Orders/${orderID}/BusinessSearchResults`

        return this.http.get<BusinessSearchResult[]>(
            `${this.config.rootUrl}${urlSlug}`,
            this.generateHeaders());
    }

    public getBusinessSearchResultsFilters(orderID: string) {
        var uri = `${this.config.rootUrl}/Orders/${orderID}/BusinessSearchResults/Filters`;

        return this.http.get<BusinessSearchResultsFilters>(uri);
    }
}
