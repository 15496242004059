import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FiltersComponent } from '../../ui-common/filters-component';
import { Order } from '../../interfaces/orders/order';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';

@Component({
  selector: 'lib-message-list-filter',
  templateUrl: './message-list-filter.component.html',
  styleUrls: ['./message-list-filter.component.css']
})
export class MessageListFilterComponent extends FiltersComponent<Order> implements OnInit, OnDestroy {
  @Input() isAdmin: boolean = false;
  
  constructor(
    repo: OrderRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe) {
      super(
        repo,
        formBuilder,
        datePipe
      )
  }

  ngOnInit() {
    let readByAdmin = this.isAdmin ? false : null;
    let readByClient = !this.isAdmin ? false : null;

    this._filterForm = this.formBuilder.group({
        isVisible: [true],
        orderMessageIsReadByAdmin: [readByAdmin],
        orderMessageIsReadByClient: [readByClient],
        sortColumn: ["OrderStatusUpdatedDate"],
        sortDirection: ["desc"]
    })

    this._defaultParams = this.buildParamsFromForm(this._filterForm);
    
    this.repo.getFirstPage(this._defaultParams);
  }

  ngOnDestroy(): void {
    this.repo.clearFilters();
  }
}