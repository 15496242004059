import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderManagerService } from '../../../lib/services/order-manager/order-manager.service';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { Order } from '../../interfaces/orders/order';
import { eOrderType } from '../../enums';

@Component({
  selector: 'lib-lien-lifetime',
  templateUrl: './lien-lifetime.component.html',
  styleUrls: ['./lien-lifetime.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LienLifetimeComponent implements OnInit {
  registrationDate: Date | null | undefined = null;
  expiryDate: Date | null | undefined = null;
  progress = 0;
  private unsubscribe$ = new Subject<void>();
  timeRemaining = '';
  isInfiniteTerm = false;

  constructor(protected orderManager: OrderManagerService) {}

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => {
        this.registrationDate = new Date(order.referenceRegistrationInformation?.originalRegistrationDate ?? 
          order.lien!.registrationResults?.[0]?.registrationDate ?? 
          order.orderStatusUpdatedDate!)
        this.isInfiniteTerm = order.lien?.isInfiniteTerm ?? false;
        if (!this.isInfiniteTerm) {
          this.expiryDate = this.getExpiryDate(order);
        }

        this.calculateProgress();
        this.calculateTimeRemaining();
    });
  }

  calculateProgress(): void {
    if (this.isInfiniteTerm) {
      this.progress = 100;
    } else {
      const now = new Date();
      
      const endOfExpiryDate = new Date(this.expiryDate!);
      endOfExpiryDate.setHours(23, 59, 59, 999);
      
      const totalDuration = endOfExpiryDate.getTime() - this.registrationDate!.getTime();
      const elapsed = now.getTime() - this.registrationDate!.getTime();
      const rawProgress = (elapsed / totalDuration) * 100;
      
      this.progress = Math.min(rawProgress, 100);
    }
  }

  calculateTimeRemaining(): void {
    if (this.isInfiniteTerm) {
      this.timeRemaining = 'Permanent';
    } else if (this.expiryDate) {
      const now = new Date();

      const endOfExpiryDate = new Date(this.expiryDate);
      endOfExpiryDate.setHours(23, 59, 59, 999);

      const remainingTime = endOfExpiryDate.getTime() - now.getTime();
      if (remainingTime > 0) {
        const daysRemaining = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
        var dayPluralization = daysRemaining > 1 ? "days" : "day";
        this.timeRemaining = `${daysRemaining} ${dayPluralization} remaining`;
      } else {
        this.timeRemaining = 'Expired';
      }
    }
  }

  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  calculateExpiryDateFromTerm(registrationDate: Date, term: number): Date {
    const expiryDate = new Date(registrationDate); // Clone the original date
    expiryDate.setFullYear(expiryDate.getFullYear() + term);
    return expiryDate;
  }

  getExpiryDate(order: Order) {
    // If we have a requested expiry date use that
    if (order.lien?.expiryDate) {
      return new Date(order.lien.expiryDate);
    } else if(order.lien?.registrationResults?.at(0)?.expiryDate) {
      return new Date(order.lien.registrationResults.at(0)!.expiryDate!);
    } else if(order.referenceRegistrationInformation?.expiryDate && order.lien?.term) {
      return this.calculateExpiryDateFromTerm(order.referenceRegistrationInformation.expiryDate, order.lien.term)
    } else {
      // otherwise, calculate expiry date based on term
      return this.calculateExpiryDateFromTerm(this.registrationDate!, order.lien!.term!);
    }
  }
}
