import { Order } from '../../interfaces/orders/order';
import { Party } from "../../interfaces/parties/party";
import { SearchJurisdiction } from "../../interfaces/search-jurisdictions/search-jurisdiction";
import { Province } from "../../interfaces/province";

export abstract class OrderPreviewComponent {

  provinces: Province[] = [
    { value: 'AB', viewValue: 'AB' },
    { value: 'BC', viewValue: 'BC' },
    { value: 'MB', viewValue: 'MB' },
    { value: 'NB', viewValue: 'NB' },
    { value: 'NL', viewValue: 'NL' },
    { value: 'NS', viewValue: 'NS' },
    { value: 'ON', viewValue: 'ON' },
    { value: 'PE', viewValue: 'PE' },
    { value: 'QC', viewValue: 'QC' },
    { value: 'SK', viewValue: 'SK' },
    { value: 'NT', viewValue: 'NT' },
    { value: 'NU', viewValue: 'NU' },
    { value: 'YT', viewValue: 'YT' },
    { value: '_F', viewValue: 'FED' }
  ]

  protected getFirstDebtorName(order: Order): string {
    let debtor = order.parties?.find(party => party.partyType.name.includes("Debtor"));
    return this.getPartyName(debtor);
  }

  protected getFirstSecuredPartyName(order: Order): string {
    let securedParty = order.parties?.find(party => party.partyType.name.includes("Secured"));
    return this.getPartyName(securedParty);
  }

  private getPartyName(party: Party | null | undefined): string {
    let name = "";

    if (party?.busName) {
      name = party.busName;
    } else if (party?.firstName) {
      name = `${party.firstName} ${party.lastName}`;
    }

    return name;
  }

  protected getSearchCriteria(order: Order): string {
    let party = order.parties?.at(0);
    let asset = order.assets?.at(0);
    let criteria = "";

    if (party?.busName) {
      criteria += party.busName ?? "";

      if (order.jurisdiction == "QC" &&
        party.contactDetails?.address?.postalCode) {
        criteria += ` ${party.contactDetails.address.postalCode}`;
      }
    } else if (party?.firstName) {
      criteria = `${party.firstName} ${party.lastName}`;
    } else if (asset) {
      criteria = asset.serialNumber;
    }
    else if (order.businessSearchCriteria?.name) {
      criteria = order.businessSearchCriteria.name;
    }
    else if (order.businessSearchCriteria?.number) {
      criteria = order.businessSearchCriteria.number;
    }
    else if (order.businessReportCriteria?.number) {
      criteria = order.businessReportCriteria.number;
    }

    return criteria;
  }

  protected getSearchJurisdictions(order: Order): string {
    return this.getJurisdictionLabels(order.searchParameters?.searchJurisdictions).join(', ');
  }

  private getJurisdictionLabel(jurisdiction: string): string {
    const province = this.provinces.find(p => p.value === jurisdiction);
    return province ? province.viewValue : 'Unknown Jurisdiction';
  }

  private getJurisdictionLabels(jurisdictions: SearchJurisdiction[] | undefined): string[] {
    if (jurisdictions === undefined) {
      jurisdictions = [];
    }

    return jurisdictions.map(j => this.getJurisdictionLabel(j.jurisdiction));
  }
}