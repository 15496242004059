import { animate, state, style, transition, trigger } from "@angular/animations";

export const SlideDownAnimation = trigger('slideDown', [
    state('hidden', style({
      margin: '0px',
      padding: '0px',
      height: '0px',
      opacity: 0
    })),
    state('visible', style({
      margin: '*',
      padding: '*',
      height: '*',
      opacity: 1
    })),
    transition('hidden <=> visible', [
      animate('0.5s cubic-bezier(0.5, 0.1, 0.1, 1)')
    ])
  ]);