import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from "ngx-toastr";
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Collection } from '../../interfaces/collections/collection';
import { CollectionForCreation } from '../../interfaces/collections/collection-for-creation';

@Injectable({
    providedIn: 'root'
})
export class CollectionsRepositoryService extends PaginatedRepository<Collection> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLinkTemplate = `${this.config.rootUrl}/Corporations/[0]/Collections`;
        this._baseLink = `${this.config.rootUrl}/Collections`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getCollection = (collectionID: string) => {
        return this.http.get<Collection>(`${this._baseLink}/${collectionID}`);
    }

    public createCollection = (collection: CollectionForCreation) => {
        return this.http.post<Collection>(this._baseLink, collection);
    }
}