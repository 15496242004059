import { Component } from '@angular/core';
import { PartyListComponent } from '../party-list/party-list.component';
import { DatePipe } from '@angular/common';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service'
import { ePartyType } from '../../enums';

@Component({
  selector: 'lib-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.css']
})
export class DealerListComponent extends PartyListComponent {
  constructor(
    datePipe: DatePipe,
    private emptyEntityService: EmptyEntityService) {
    super(datePipe);
  }

  protected addNewDealer() {
    let newParty = this.emptyEntityService.getEmptyParty(ePartyType.businessDealer, this.order?.id ?? '');
    this.partyAddedEvent.emit(newParty);
  }

  protected removeDealer() {
    let newParty = this.emptyEntityService.getEmptyParty(ePartyType.businessDealer, this.order?.id ?? '');
    this.partyAddedEvent.emit(newParty);
  }
}
