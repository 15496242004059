import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { RegistryDisbursement } from '../../interfaces/registry-disbursements/registry-disbursement';
import { RegistryDisbursementForCreation } from '../../interfaces/registry-disbursements/registry-disbursement-for-creation';


@Injectable({
  providedIn: 'root'
})
export class RegistryDisbursementsRepositoryService extends PaginatedRepository<RegistryDisbursement> {

  protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(http, messageService);

    this._baseLink = `${this.config.rootUrl}/RegistryDisbursements`;
  }

  public createRegistryDisbursement(orderID: string, registryDisbursement: RegistryDisbursementForCreation): Observable<RegistryDisbursement> {
    return this.http.post<RegistryDisbursement>(`${this.config.rootUrl}/Orders/${orderID}/RegistryDisbursements`, registryDisbursement);
  }

  public createRegistryDisbursementByOrder(orderID: string): Observable<RegistryDisbursement> {
    return this.http.post<RegistryDisbursement>(`${this.config.rootUrl}/Orders/${orderID}/RegistryDisbursements/All`, null);
  }

  public getRegistryDisbursementsByOrder(orderID: string): Observable<RegistryDisbursement[]> {
    var parameters = new HttpParams().set("PageSize", 100).set("OrderID", orderID);

    return this.http.get<RegistryDisbursement[]>(`${this.config.rootUrl}/RegistryDisbursements`, { params: parameters });
  }

  public deleteRegistryDisbursement(orderID: string, id: string) {
    return this.http.delete(`${this.config.rootUrl}/Orders/${orderID}/RegistryDisbursements/${id}`);
  }
}
