import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'fullCountryName'
})
export class FullCountryNamePipe implements PipeTransform {
    transform(country: string) {
        switch (country) {
            case 'CA': return 'Canada';
            default: return country;
        }
    }
}