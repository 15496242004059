import { Component, Input } from '@angular/core';
import { eOrderStatusType } from '../../enums';
import { Order } from '../../interfaces/orders/order';

@Component({
  selector: 'lib-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css']
})
export class OrderStatusComponent {
  @Input() order!: Order;

  getStatusIcon() {
    switch (this.order.orderStatusTypeID) {
      case eOrderStatusType.historical:
        return 'book_5';
      case eOrderStatusType.historicalDraft:
        return 'menu_book';
      case eOrderStatusType.complete:
        return 'check_circle_outline';
      case eOrderStatusType.pending:
        return 'schedule';
      case eOrderStatusType.invalid:
        return 'error_outline';
      case eOrderStatusType.cancelled:
        return 'block';
      default: return '';
    }
  }

  getStatusIconColor() {
    switch (this.order.orderStatusTypeID) {
      case eOrderStatusType.complete:
        return { color: '#8BA77D' };
      case eOrderStatusType.pending:
      case eOrderStatusType.historical:
      case eOrderStatusType.historicalDraft:
        return { color: '#2B73A8' };
      case eOrderStatusType.invalid:
      case eOrderStatusType.cancelled:
        return { color: '#FB2C38' };
      default: return { color: 'black' };
    }
  }
}
