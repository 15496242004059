<mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">

        <div class="flex" *ngIf="isAdmin">
            <mat-radio-group formControlName="orderMessageIsReadByAdmin" class="flex-item">
                <mat-radio-button [value]="false">Unread</mat-radio-button>
                <mat-radio-button [value]="true">Read</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="flex" *ngIf="!isAdmin">
            <mat-radio-group formControlName="orderMessageIsReadByClient" class="flex-item">
                <mat-radio-button [value]="false">Unread</mat-radio-button>
                <mat-radio-button [value]="true">Read</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="spacer-small"></div>
        <div>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>