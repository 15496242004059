<mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex">
            <mat-form-field class="form-field flex-item">
                <mat-label>Name</mat-label>
                <input matInput [formControlName]="'name'" placeholder="Name" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Number</mat-label>
                <input matInput [formControlName]="'number'" placeholder="Number" />
            </mat-form-field>
        </div>

        <div class="flex">
        </div>

        <div class="flex">
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="status" placeholder="Status" multiple="true">
                    <mat-option *ngFor="let option of statuses" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="jurisdiction" placeholder="Jurisdiction" multiple="true">
                    <mat-option *ngFor="let option of jurisdictions" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="companyType" placeholder="Company Type" multiple="true">
                    <mat-option *ngFor="let option of types" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="description" placeholder="Description" multiple="true">
                    <mat-option *ngFor="let option of descriptions" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-select formControlName="jurisdictionStatus" placeholder="Jurisdiction Status" multiple="true">
                    <mat-option *ngFor="let option of jurisdictionStatuses" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field class="form-field flex-item">
                <mat-label>Status Date From</mat-label>
                <input matInput [matDatepicker]="statusDateFrom" [formControlName]="'statusDateFrom'"
                    placeholder="Status Date From">
                <mat-datepicker-toggle matSuffix [for]="statusDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #statusDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Status Date To</mat-label>
                <input matInput [matDatepicker]="statusDateTo" [formControlName]="'statusDateTo'"
                    placeholder="Status Date To">
                <mat-datepicker-toggle matSuffix [for]="statusDateTo"></mat-datepicker-toggle>
                <mat-datepicker #statusDateTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field class="form-field flex-item">
                <mat-label>Creation Date From</mat-label>
                <input matInput [matDatepicker]="creationDateFrom" [formControlName]="'creationDateFrom'"
                    placeholder="Creation Date From">
                <mat-datepicker-toggle matSuffix [for]="creationDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #creationDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Creation Date To</mat-label>
                <input matInput [matDatepicker]="creationDateTo" [formControlName]="'creationDateTo'"
                    placeholder="Creation Date To">
                <mat-datepicker-toggle matSuffix [for]="creationDateTo"></mat-datepicker-toggle>
                <mat-datepicker #creationDateTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex" *ngIf="showHiddenFilter">
            <mat-radio-group formControlName="showResult" class="flex-item">
                <mat-radio-button [value]="false">Hidden</mat-radio-button>
                <mat-radio-button [value]="null">Both</mat-radio-button>
                <mat-radio-button [value]="true">Shown</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="spacer-small"></div>
        <div>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>