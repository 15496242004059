import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { SearchOrderPreviewComponent } from '../search-order-preview/search-order-preview.component';
import { RegistrationOrderPreviewComponent } from '../registration-order-preview/registration-order-preview.component';
import { DefaultOrderPreviewComponent } from '../default-order-preview/default-order-preview.component';
import { Order } from '../../interfaces/orders/order';
import { RenewalOrderPreviewComponent } from '../renewal-order-preview/renewal-order-preview.component';

@Component({
  selector: 'lib-order-preview-host',
  template: '<ng-container #orderPreviewContainer></ng-container>'
})
export class OrderPreviewHostComponent {
  @ViewChild('orderPreviewContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  @Input() order!: Order;

  ngOnInit(): void {
    let componentClass;

    if (this.order.orderType.name.includes("Debtor Search") ||
      this.order.orderType.name.includes("Asset Search") || 
      this.order.orderType.name.includes("Asset Multi Search") ||
      this.order.orderType.name.includes("Business Search") ||
      this.order.orderType.name.includes("Business Report")) {
      componentClass = SearchOrderPreviewComponent;
    } else if (this.order.orderType.name.includes("Lien") ||
      this.order.orderType.name.includes("Discharge") ||
      this.order.orderType.name.includes("Amendment")) {
      componentClass = RegistrationOrderPreviewComponent;
    } else if (this.order.orderType.name.includes("Renewal")) {
      componentClass = RenewalOrderPreviewComponent;
    } else {
      componentClass = DefaultOrderPreviewComponent;
    }

    let previewContainerRef = this.container.createComponent(componentClass);
    (previewContainerRef.instance as any).order = this.order;
  }
}
