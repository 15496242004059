import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, skip, Subscription } from 'rxjs';

@Component({
  selector: 'lib-error-bubble',
  templateUrl: './error-bubble.component.html',
  styleUrls: ['./error-bubble.component.css'],
  animations: [
    trigger('fadeInDown', [
      state('void', style({
        opacity: 0,
        transform: 'translate(-50%, -10px)'
      })),
      transition(':enter', [
        animate('0.1s ease-out', style({
          opacity: 1,
          transform: 'translate(-50%, 0)'
        }))
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        animate('0.075s ease-out', style({
          opacity: 0,
          transform: 'translate(-50%, 0)'
        }))
      ])
    ])
  ]
})
export class ErrorBubbleComponent implements OnInit, OnDestroy {
  @Input() message!: Observable<string | null | undefined>;
  @Input() control: AbstractControl<any, any> | undefined;
  @Input() showErrorsOnLoad = false;

  show: boolean = false;
  _message: string | null | undefined;

  messageSubscription$: Subscription | undefined;

  ngOnInit(): void {
    this.message.subscribe(message => {
      if (message) {
        this._message = message;
      }

      if (this.control) {
        if (this.showErrorsOnLoad) {
          this.show = !!message;
          this.control.setErrors(this.show ? {} : null, { emitEvent: false });
        } else {
          this.show = (this.control.touched || !this.control.pristine) && this.control.dirty && !!message;

          if ((this.control.touched || !this.control.pristine) && !!message) {
            this.control.setErrors({}, { emitEvent: false });
          } else {
            this.control.setErrors(null, { emitEvent: false });
          }
        }
      } else {
        this.show = !!message;
      }
    })
  }

  ngOnDestroy(): void {
    this.messageSubscription$?.unsubscribe();
  }

  hide(): void {
    this.show = false;
  }
}