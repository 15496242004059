import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-disclosure-indicator',
  templateUrl: './disclosure-indicator.component.html',
  styleUrls: ['./disclosure-indicator.component.css']
})
export class DisclosureIndicatorComponent {
  @Input() isExpanded: boolean = false;
  @Input() color: string | undefined;
}
